<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <div style="width:100%;background-color:#508caa;margin-bottom: 15px;color: #fff;padding: 20px">注意事项：为了确保认证阶段顺利通过，请确保所填信息准确性！<br>

      </div>
      <div>
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="240px"
            class="demo-ruleForm"
            status-icon
            style="width: 90%; margin: 0 auto;"
        >
          <el-form-item label="执业许可证机构名称：" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请填写执业许可证上的机构名称"/>
          </el-form-item>
          <el-form-item label="执业许可证登记号：" prop="licenseNo">
            <el-input v-model="ruleForm.licenseNo" placeholder="执业许可证登记号"/>
          </el-form-item>
          <el-form-item label="执业许可证过期日期："  prop="expiredDate">
            <el-date-picker
                v-model="ruleForm.expiredDate"
                type="date"
                label="执业许可证过期日期"
                placeholder="执业许可证过期日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
            />
            <el-checkbox v-model="ruleForm.expiration" @change="$refs.ruleFormRef.validateField('expiredDate', () => null)">无有效期</el-checkbox>
          </el-form-item>
          <el-form-item label="上传许可证正面照片：" prop="licenseFrontPath">
            <el-upload
                ref="upload"
                class="upload-demo"
                :action="basicUrl+'/api/dmz/ORGAN/file/upload'"
                :limit="1"
                :file-list="licenseFrontFileList"
                :on-success="handleExceed01"
                :before-upload="beforeAvatarUpload01"
                list-type="picture"
            >
              <template #trigger>
                <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传承诺函：" prop="promisePath">
            <el-upload
                ref="upload"
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                :action="basicUrl+'/api/dmz/ORGAN/file/upload'"
                :limit="1"
                :file-list="promiseFileList"
                :on-success="handleExceed02"
                :before-upload="beforeAvatarUpload02"
            >
              <template #trigger>
                <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传任命书：" prop="appointPath">
            <el-upload
                ref="upload"
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                :action="basicUrl+'/api/dmz/ORGAN/file/upload'"
                :limit="1"
                :file-list="appointFileList"
                :on-success="handleExceed03"
                :before-upload="beforeAvatarUpload03"
            >
              <template #trigger>
                <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item label="医院是否有相同法人代表的分院：" prop="branch">
            <el-radio-group v-model="ruleForm.branch">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="省      份：" prop="province">
            <el-select v-model="ruleForm.province" placeholder="未选择" @change="searchCity">
              <el-option v-for="item in provinceList" :label="item.name" :value="item.id" :key="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="城      市：" prop="city">
            <el-select v-model="ruleForm.city" placeholder="未选择" @change="searchCounty">
              <el-option v-for="item in cityList" :label="item.name" :value="item.id" :key="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="区      县：" prop="districtId">
            <el-select v-model="ruleForm.districtId" placeholder="未选择">
              <el-option v-for="item in countyList" :label="item.name" :value="item.id" :key="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="地      址：" prop="address">
            <el-input v-model="ruleForm.address" placeholder="地      址"/>
          </el-form-item>
          <el-form-item label="法人代表：" prop="legal">
            <el-input v-model="ruleForm.legal" placeholder="法人代表"/>
          </el-form-item>
          <el-form-item label="床      位：" prop="bed">
            <el-input v-model="ruleForm.bed" placeholder="床      位"/>
          </el-form-item>
          <el-form-item label="等      级：" prop="level">
            <el-select v-model="ruleForm.level" placeholder="未选择">
              <el-option v-for="item in levels" :label="item.desc" :value="item.name" :key="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="职工人数：" prop="employees">
            <el-input-number v-model="ruleForm.employees" style="width: 30%" placeholder="职工人数"/>
          </el-form-item>
          <el-form-item label="本院胸痛中心是否已成立：" prop="cpc">
            <el-radio-group v-model="ruleForm.cpc">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="认证负责人姓名：" prop="leaderName">
            <el-input v-model="ruleForm.leaderName" placeholder="认证负责人姓名"/>
          </el-form-item>
          <el-form-item label="认证负责人职务：" prop="leaderPost">
            <el-input v-model="ruleForm.leaderPost" placeholder="认证负责人职务"/>
          </el-form-item>
          <el-form-item label="认证负责人邮箱：" prop="leaderEmail">
            <el-input v-model="ruleForm.leaderEmail" placeholder="认证负责人邮箱"/>
          </el-form-item>
          <el-form-item label="认证负责人手机号码：" prop="leaderPhone">
            <el-input v-model="ruleForm.leaderPhone" placeholder="认证负责人手机号码"/>
          </el-form-item>
          <el-divider border-style="dashed" />
          <el-form-item label="联络员姓名：" prop="linkmanName">
            <el-input v-model="ruleForm.linkmanName" placeholder="联络员姓名"/>
          </el-form-item>
          <el-form-item label="联络员职务：" prop="linkmanPost">
            <el-input v-model="ruleForm.linkmanPost" placeholder="联络员职务"/>
          </el-form-item>
          <el-form-item label="联络员手机：" prop="linkmanPhone">
            <el-input v-model="ruleForm.linkmanPhone" placeholder="联络员手机"/>
          </el-form-item>
          <el-form-item label="联络员邮箱：" prop="linkmanEmail">
            <el-input v-model="ruleForm.linkmanEmail" placeholder="联络员邮箱"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="background-color: #1c69a2" @click="submitForm('ruleFormRef')" size="large" :loading="loading">保存</el-button>
            <el-button @click="goBack" size="large">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>



  </div>
</template>

<script>
import { getDistrict, getOrganizationLevel, postOrganizationEdit } from '@/api/index'
export default {
  name: "EditMessagePectoralgia",
  data() {
    const validateExpired = (rule, value, callback) => {
      if (!value && !this.ruleForm.expiration) {
        callback(new Error('请填写执业许可证过期日期'))
      }else {
        callback()
      }
    }
    //手机号验证
    const validatePhone1=(rule, value, callback) =>{
      if(!(/^1[3456789]\d{9}$/.test(value)) && this.ruleForm.leaderPhone){
        callback(new Error('手机号码不合法，请重新输入'))
      }else {
        callback()
      }
    }
    const validatePhone2=(rule, value, callback) =>{
      if(!(/^1[3456789]\d{9}$/.test(value)) && this.ruleForm.linkmanPhone ){
        callback(new Error('手机号码不合法，请重新输入'))
      }else {
        callback()
      }
    }
    //邮箱验证
    const validateEmail1=(rule, value, callback) =>{
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if(!(emailRegex.test(value)) && this.ruleForm.leaderEmail ){
        callback(new Error('邮箱不合法，请重新输入'))
      }else {
        callback()
      }
    }
    const validateEmail2=(rule, value, callback) =>{
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if(!(emailRegex.test(value)) && this.ruleForm.linkmanEmail ){
        callback(new Error('邮箱不合法，请重新输入'))
      }else {
        callback()
      }
    }
    return {
      activeName: 'first',
      loading: false,
      ruleForm: {
        type: 'THORACALGIA', // THORACALGIA("胸痛中心注册"),RESCUE("救治单元注册")
        province: '',
        districtId: '',
        agree: false
      },
      basicUrl : this.$basicUrl,
      rules: {
        name: [{ required: true, message: '请填写执业许可证上的机构名称', trigger: 'blur' }],
        licenseNo: [{ required: true, message: '请填写执业许可证登记号', trigger: 'blur' }],
        expiredDate: [{ required: true, validator: validateExpired, trigger: 'blur' }],
        licenseFrontPath: [{ required: true, message: '请上传许可证正面照片', trigger: 'blur' }],
        promisePath: [{ required: false, message: '请上传承诺函照片', trigger: 'blur' }],
        appointPath: [{ required: false, message: '请上传任命书照片', trigger: 'blur' }],
        branch: [{ required: true, message: '请选择医院是否有相同法人代表的分院', trigger: 'blur' }],
        address: [{ required: true, message: '请填写地址', trigger: 'blur' }],
        legal: [{ required: true, message: '请填写法人代表', trigger: 'blur' }],
        bed: [{ required: true, message: '请填写床位', trigger: 'blur' }],
        employees: [{ required: true, message: '请填写职工人数', trigger: 'blur' }],
        leaderName: [{ required: true, message: '请填写认证负责人姓名', trigger: 'blur' }],
        leaderPost: [{ required: true, message: '请填写认证负责人职务', trigger: 'blur' }],
        leaderEmail: [{ required: true, validator:validateEmail1,message: '请填写认证负责人正确邮箱', trigger: 'blur' }],
        leaderPhone: [{ required: true, validator:validatePhone1,message: '请填写认证负责人正确手机号码', trigger: 'blur' }],
        linkmanName: [{ required: true, message: '请填写联络员姓名', trigger: 'blur' }],
        linkmanPost: [{ required: true, message: '请填写联络员职务', trigger: 'blur' }],
        linkmanEmail: [{ required: true,validator:validateEmail2, message: '请填写联络员正确邮箱', trigger: 'blur' }],
        linkmanPhone: [{ required: true,validator:validatePhone2, message: '请填写联络员正确手机号码', trigger: 'blur' }],
        loginName: [{ required: true, message: '请填写登录账号', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省份', trigger: 'change'}],
        districtId: [{ required: true, message: '请选择区县', trigger: 'change'}],
        city: [{ required: true, message: '请选择城市', trigger: 'change'}],
        level: [{ required: true, message: '请选择等级', trigger: 'change'}],
        cpc: [{ required: true, message: '请选择本院胸痛中心是否已成立', trigger: 'change'}],
      },
      levels: [],
      provinceList: [],
      cityList: [],
      countyList: [],
      licenseFrontFileList: [],
      promiseFileList:[],
      appointFileList:[],
    }
  },
  mounted() {
    const self=this;
    self.reqDistrict('', 'PROVINCE')
    self.getOrganLevel()
    let id = self.$route.query.id;

    self.getOrganMessage(id);
  },
  methods: {
    getOrganMessage:function (id){
      const self=this;
      this.$axios.get('/api/organization/register/'+id)
          .then((res) => {
            if(res.msg.flag == 0){
              self.ruleForm= {...self.ruleForm, ...res.biz};
              if (self.ruleForm.licenseFrontPathUrl) {
                self.licenseFrontFileList = [{name: '许可证正面照片', url: self.ruleForm.licenseFrontPathUrl}]

              }
              if(this.ruleForm.appointUrl){
                this.appointFileList=[{name: '任命书', url: this.ruleForm.appointUrl}];
              }
              if(this.ruleForm.promiseUrl){
                this.promiseFileList=[{name: '承诺函', url: this.ruleForm.promiseUrl}];
              }

              if (this.ruleForm.district) {
                let ancestors = this.ruleForm.district && this.ruleForm.district.ancestors ? this.ruleForm.district.ancestors : []
                if (ancestors.length == 3) {
                  this.reqDistrict(ancestors[0], 'CITY')
                  this.reqDistrict(ancestors[1], 'COUNTY')
                  this.ruleForm.province = ancestors[0]
                  this.ruleForm.city = ancestors[1]
                  this.ruleForm.districtId = ancestors[2]
                } else if(ancestors.length == 2) {
                  this.reqDistrict(ancestors[0], 'CITY')
                  this.reqDistrict(ancestors[1], 'COUNTY')
                  this.ruleForm.province = ancestors[0]
                  this.ruleForm.city = ancestors[1]
                  this.ruleForm.districtId = this.ruleForm.district.oid || ''
                } else if(ancestors.length == 1) {
                  this.reqDistrict(ancestors[0], 'CITY')
                  this.ruleForm.province = ancestors[0]
                }
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    reqEditSubmit() {
      postOrganizationEdit(this.ruleForm).then((res) => {
        if(res.msg.flag === 0) {
          this.$message.success(res.msg.message)
          this.$router.push('/auth/organ/')
        } else {
          this.$message.error(res.msg.message)
        }
        this.loading = false
      })
          .catch(() => {
            this.loading = false
          })
    },
    async submitForm(formEl) {
      this.$refs[formEl].validate((valid, fields) => {
        if (valid) {
          this.reqEditSubmit()
        } else {
        }
      })
    },
    resetForm(formEl) {
      if (!formEl) return
      this.$refs[formEl].resetFields()
    },
    async getOrganLevel() {
      let levels=[];
      const res = await getOrganizationLevel()
      levels = res.biz
      this.levels=levels.filter(item => item.name != 'ONE')
    },
    async reqDistrict(parentCode, level) {
      const res = await getDistrict({parentCode, level: level})
      if (level === 'PROVINCE') {
        this.provinceList = res.biz
        this.cityList = []
        this.countyList = []
      }
      if (level === 'CITY') {
        this.cityList = res.biz
        this.countyList = []
      }
      if (level === 'COUNTY') {
        this.countyList = res.biz
      }

    },
    searchCity(val) {
      this.reqDistrict(val, 'CITY')
      this.ruleForm.districtId = ''
    },
    searchCounty(val) {
      this.reqDistrict(val, 'COUNTY')
      this.ruleForm.districtId = ''
    },
    handleExceed01(res, index) {
      if(res && res.msg.flag === 0) {
        this.ruleForm.licenseFrontPath = res.biz.path;
        this.licenseFrontFileList = [{name: res.biz.name, url: res.biz.url}]
      }
    },
    beforeAvatarUpload01(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        this.$message.error('请上传jpg或者png格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('图片文件大小不超过2MB!')
        return false
      }
      return true
    },
    handleExceed02(res, index) {
      if(res && res.msg.flag === 0) {
        this.ruleForm.promisePath = res.biz.path
        this.promiseFileList=[{name: res.biz.name, url: res.biz.url}]
      }
    },
    beforeAvatarUpload02(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf') {
        this.$message.error('请上传jpg或者png或者pdf格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('文件大小不超过2MB!')
        return false
      }
      return true
    },
    handleExceed03(res, index) {
      if(res && res.msg.flag === 0) {
        this.ruleForm.appointPath = res.biz.path;
        this.appointFileList=[{name: res.biz.name, url: res.biz.url}]
      }
    },
    beforeAvatarUpload03(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf') {
        this.$message.error('请上传jpg或者png或者pdf格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('文件大小不超过2MB!')
        return false
      }
      return true
    },
    goBack:function (){
      this.$router.go(-1);
    }
  }
}

</script>
<style lang="scss">
.register-tabs{
  background: #2790ca;
  border-radius: 10px;
  .el-radio{
    height: 52px;
    line-height: 42px;
    padding: 0 20px;
    .el-radio__label{
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #fff;
    }
    .el-radio__inner{
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
    .el-radio__input{
      line-height: normal;
    }
  }
  .el-radio.is-checked{
    border-radius: 30px;
    box-shadow: 0 0 6px 3px #1b4773 inset;
    background: linear-gradient(90deg, #20558a, #20558a);
    .el-radio__inner::after{
      width: 11px;
      height: 11px;
      background: #01c5ff;
    }
    .el-radio__inner::before{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 16px;
      transform: translate(-50%,-50%) scale(1);
      background: #fff;
      border-radius: 50%;
    }
  }
}
</style>
